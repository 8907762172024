import { render, staticRenderFns } from "./DashboardForm.vue?vue&type=template&id=651f5c1c&scoped=true&"
import script from "./DashboardForm.vue?vue&type=script&lang=ts&"
export * from "./DashboardForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_vue-template-compiler@2.7.15_webpack@5.80.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651f5c1c",
  null
  
)

export default component.exports